#root{
  font-family: 'Montserrat';
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}
@layer components {
  .html {
    @apply text-secondary-400;
  }
  .sign-up {
    @apply text-secondary-100;
  }

  .menu a {
    @apply pl-3.5 pr-3.5 text-xs font-semibold text-secondary-400 hover:text-black;
  }
  .input-d {
    @apply input input-bordered input-primary h-10 rounded-none border-secondary text-sm font-semibold placeholder:font-normal   placeholder:text-secondary hover:border-black focus:border-primary-focus focus:text-primary-focus;
  }
  .btn-primary-d {
    @apply h-10 min-h-0 btn rounded-none bg-primary text-base normal-case text-white hover:bg-primary-hover focus:bg-primary-focus active:bg-primary-active;
  }
  .info-color{
    @apply text-info hover:border-info-hover hover:bg-inherit hover:text-info-hover focus:text-info-focus active:text-info-active;
  }
  .btn-outline-d {
    @apply h-10 min-h-0 btn btn-outline rounded-none text-base normal-case info-color;
  }

  .btn-disabled{
    @apply bg-secondary-200 text-secondary-300 border-secondary;
  }
}
@layer utilities {
 

}

h1 {
  @apply text-4xl;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl;
}
h4 {
  @apply text-lg;
}
h5 {
  @apply text-base
}
h6 {
  @apply text-sm
}



@media screen and (max-width: theme("screens.md")) {
  h1 {
    @apply text-xl;
  }
  h2 {
    @apply text-lg;
  }
  h3 {
    @apply text-base;
  }
  h4 {
    @apply text-sm;
  }
  h5 {
    @apply text-xs;
  }
  h5 {
    @apply text-2xs;
  }
}

input {
  @apply input input-bordered input-primary h-10 rounded-none border-secondary text-sm font-semibold placeholder:font-normal   placeholder:text-secondary hover:border-black focus:border-primary-focus focus:text-primary-focus;
}

input[type="checkbox"] {
  @apply shadow-none ring-0 checked:shadow-none checked:ring-0 focus:shadow-none focus:ring-0;
  @apply checked:bg-secondary-100 checked:focus:bg-primary-focus;
}
input[type="radio"] {
  @apply shadow-none ring-0 checked:shadow-none checked:ring-0 focus:shadow-none focus:ring-0;
  @apply checked:bg-secondary-100 checked:focus:bg-primary-focus;
}

body::-webkit-scrollbar {
  display: none;
}

@import "./app/components/Components.css";
@import "./app/pages/Pages.css";
