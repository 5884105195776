.start-bg {
  background: linear-gradient(
    to bottom,
    theme("colors.gold-yellow-500") 0%,
    theme("colors.gold-yellow-500") 409px,
    #ffffff 409px,
    #ffffff 100%
  );
}


.ask-athlet-bg {
  background: linear-gradient(
    to bottom,
    theme("colors.gold-yellow-500") 0%,
    theme("colors.gold-yellow-500") 70%,
    #ffffff 70%,
    #ffffff 100%
  );
}

